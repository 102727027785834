import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const FacilitiesGrid = props => {

  const { facilities } = props;

  return (
    <div className="grid gap-y-8 gap-x-8 max-w-sm sm:grid-cols-2 sm:max-w-none sm:gap-x-10 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-12">
      {facilities.map((facility, index) => (
        <Link
          key={index}
          className="group flex flex-col"
          to={`/facilities/${facility.slug}/`}
        >
          <h2 className="font-league font-semibold flex items-end text-2xl mb-2 sm:h-16 xl:text-3xl xl:h-18 group-hover:text-orange-2 transition duration-300">{facility.title}</h2>
          <div className="relative w-full mb-3 h-48 md:h-56 xl:h-64">
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}/${facility.image}`}
              alt={facility.title}
              className="object-cover object-center w-full h-full"
            />
            <div className="absolute top-2 bg-white right-2 rounded px-1.5 py-0.5 border-2 border-orange text-orange-2 bg-opacity-90 text-xs font-semibold">
              {facility.is_core ? "Core Facility" : "Consultation/Collaborative Activity"}
            </div>
          </div>
          <div className="text-gray-500">{facility.summary}</div>
        </Link>
      ))}
    </div>
  );
};

FacilitiesGrid.propTypes = {
  facilities: PropTypes.array.isRequired,
};

export default FacilitiesGrid;