import { useParams } from "react-router-dom";
import Base from "./Base";
import { useDocumentTitle, useRemoteData } from "../hooks";
import PageNotFound from "./PageNotFound";
import { parseHtml } from "../utils";
import LabMembers from "../components/LabMembers";
import YoutubeVideos from "../components/YoutubeVideos";

const FacilityPage = () => {
  
  const { slug } = useParams();

  const data = useRemoteData(`facilities/${slug}/`);

  useDocumentTitle(data ? `SIDB - ${data.title}` : "SIDB");

  if (!data) return <Base loading={true} />;

  if (data.error) return <PageNotFound />;

  const people = [...data.pis, ...data.researchers, ...data.staff, ...data.phd_students.map(person => ({ ...person, role: "PhD Student" }))];

  const textClass = "leading-relaxed text-gray-500 mb-2 mt-2 sm:text-lg lg:mb-3";
  const h2Class = "heading border-gray-300 pb-2 mb-4 pb-0.5 mt-6 text-2xl sm:text-3xl sm:mt-8";
  const parsedHtml = parseHtml(data.body, { p: textClass, li: textClass, h2: h2Class });

  return (
    <Base className="py-12 sm:py-16">
      <div className="page-width">
        <div className="text-xl text-primary font-bold mb-1 sm:text-2xl sm:mb-2">{data.is_core ? "Core Facility" : "Consultation & Collaborative Activity"}</div>
        <h1 className="font-semibold font-league text-4xl mb-4 sm:text-5xl">{data.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: parsedHtml }} />
        {people.length > 0 && (
          <div className="my-6 sm:my-12">
            <h2 className="heading mb-6 sm:mb-16">Members</h2>
            <LabMembers people={people} link={true} />
          </div>
        )}
        {data.videos.length > 0 && (
          <YoutubeVideos videos={data.videos} />
        )}
      </div>
    </Base>
  );
};

FacilityPage.propTypes = {
  
};

export default FacilityPage;