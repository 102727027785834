import React from "react";
import Base from "./Base";
import { useDocumentTitle, useRemoteData } from "../hooks";
import FacilitiesGrid from "../components/FacilitiesGrid";

const FacilitiesPage = () => {

  const data = useRemoteData("facilities/");

  useDocumentTitle("SIDB - Facilities");

  if (!data) return <Base loading={true} />;

  const textClass = "text-lg mb-6 text-gray-500 leading-relaxed sm:text-xl sm:mb-12 sm:leading-relaxed";

  return (
    <Base className="py-12 sm:py-16">
      <div className="page-width">
        <h1 className="font-semibold font-league text-4xl mb-4 sm:text-5xl">
          Facilities
        </h1>
        <div className={textClass}>{data.intro}</div>
        <FacilitiesGrid facilities={data.facilities} />
      </div>

    </Base>
  );
};

FacilitiesPage.propTypes = {
  
};

export default FacilitiesPage;