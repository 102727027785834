import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import defaultImg from "../images/default.png";

const LabMembers = props => {

  const { people, link } = props;

  return (
    <div className="flex flex-wrap gap-x-12 gap-y-12 justify-center mx-auto w-fit sm:gap-y-20 md:gap-y-24">
      {people.map((person, index) => (
        <div key={index} className="flex flex-col justify-between items-center w-48">
          <div className="flex flex-col items-center">
            <Link to={person.pi ? `/${person.pi.slug}/${person.slug}/` : `/${person.slug}`} className="">
              <img
                src={person.image ? `${process.env.REACT_APP_MEDIA_URL}/${person.image}` : defaultImg} alt={person.name}
                className="w-44 h-44 rounded-full mb-2 object-cover inline-block"
              />
            </Link>
            <div className="font-league font-semibold text-2xl text-center mb-1">
              {person.name}
            </div>
            {person.role && (
              <div className="text-sm text-center mb-1">
                {person.role}
              </div>
            )}
            <a href={`mailto:${person.email}`} className="mb-4 block no-underline text-primary text-center text-sm">{person.email}</a>
          </div>
          {link && (
            <Link
              to={person.pi ? `/${person.pi.slug}/` : `/${person.slug}/`} 
              className={`block-link px-3 py-1.5 ${person.pi ? "" : "bg-orange hover:bg-orange-2"} text-sm`}
            >
              {person.pi?.last_name || person.last_name} Lab
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

LabMembers.propTypes = {
  people: PropTypes.array.isRequired,
  link: PropTypes.bool
};

export default LabMembers;